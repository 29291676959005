import { useMemo } from 'react';

import {
  FooterAction,
  Scanner as DiceSdkScanner,
  SuccessCallbackPayload,
} from '@dustid/dice-sdk';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAuth } from 'react-oidc-context';
import { useRecoilValue } from 'recoil';

import { useResize } from '@/common/hooks';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import { combineClass } from '@/components/Library/utility';
import { commonProps, Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';
import { selectedFacilityAtom } from '@/state/atoms/facility';
import { geolocationAtom } from '@/state/atoms/geolocation';
import { trackingDataAtom } from '@/state/atoms/mpData';

import styles from './Scanner.module.css';
import ThingRenderer from './ThingRenderer';

export const SCAN_MODES = {
  BIND: 'BIND',
  VERIFY: 'VERIFY',
  IDENTIFY: 'IDENTIFY',
  ANTITAMPER: 'ANTITAMPER',
} as const;

export type DiceScanMode = (typeof SCAN_MODES)[keyof typeof SCAN_MODES];

type Props = {
  onCancel?: (...args: any[]) => any;
  onSuccess?: (evt: SuccessCallbackPayload) => any;
  /** Required for API because of algorithm limitations, null disables. */
  catalogUuid: string | null;
  thingUuid?: string;
  className?: string;
  showPrevious?: boolean;
  placeHolder?: React.ReactNode;
  mode: 'IDENTIFY' | 'BIND' | 'VERIFY' | 'ANTITAMPER';
  actions?: FooterAction[];
  onScan?: ({
    action,
    scan,
  }: {
    action: 'IDENTIFY' | 'BIND' | 'VERIFY' | 'ANTITAMPER';
    scan: string;
  }) => any;
};

export default function Scanner({
  className,
  showPrevious = true,
  onCancel,
  onScan,
  onSuccess,
  catalogUuid,
  thingUuid,
  mode,
  placeHolder,
  actions = [],
}: Props) {
  const auth = useAuth();
  const { orgId } = useRequest();
  const { resizeRef, width, height } = useResize();
  const maxWidth = Math.min(Number(width ?? 0), 2000);

  if (!auth.user) {
    throw new Error(
      'Expected an access token to be available in the user session, component should never be rendered without a valid authenticated session',
    );
  }
  if (!auth.user.profile.sid) {
    throw new Error('Expected an `sid` value to be present on user.profile');
  }
  if (!orgId) {
    throw new Error('expected a non-null orgId');
  }

  // Control the aspect ratio of the scanner
  const trackingData = useRecoilValue(trackingDataAtom);
  const location = useRecoilValue(geolocationAtom);
  const facilityUuid = useRecoilValue(selectedFacilityAtom);

  const { onThingVerify } = useVerifiedSession();

  const handleSuccess = (evt: SuccessCallbackPayload) => {
    // Add the thing to a verified session if it was successfully bound or identified
    if (['IDENTIFY', 'BIND', 'VERIFY'].includes(evt?.action)) {
      if (evt.thingUuid) {
        onThingVerify(evt.thingUuid);
      }
    }
    onSuccess?.(evt);
  };

  const footerActions = useMemo(
    () => [
      ...(showPrevious && onCancel
        ? [
            {
              label: 'Previous',
              action: onCancel,
              buttonProps: {
                startIcon: <ArrowBackIosIcon />,
              },
            },
          ]
        : []),
      ...actions,
    ],
    [showPrevious, onCancel, actions],
  );

  const trackingEventProps = useMemo(
    () => ({
      [commonProps.pageName]: trackingData.pageName,
    }),
    [trackingData],
  );

  return (
    <div
      className={combineClass(
        styles.scanContainer,
        className,
        catalogUuid == null && styles.whiteOverlay,
      )}
      ref={resizeRef}
    >
      <DiceSdkScanner
        // DISABLE: allowNonDustScanners
        baseUrl={import.meta.env.VITE_API_URL}
        accessToken={auth.user.access_token}
        orgId={orgId}
        sessionUuid={auth.user.profile.sid}
        catalogUuid={catalogUuid}
        classNameSeed="sdk-sub-css"
        footerActions={footerActions}
        mode={mode}
        onClose={() => undefined}
        onModeChange={() => undefined}
        onSuccess={handleSuccess}
        maxWidth={maxWidth}
        maxHeight={height}
        thingUuid={thingUuid}
        // setSnackbarDomNode,
        facilityUuid={facilityUuid}
        geolocation={location}
        renderThing={(thing) => <ThingRenderer thing={thing} />}
        trackingEventProps={trackingEventProps}
        onMixpanelTrack={Mixpanel.track}
        onScan={onScan}
      />
      {placeHolder}
    </div>
  );
}
